.contact-us-link-menu + .feedback-area {
	@include screen(custom, max, $max-sm) {
		margin-top:-11px;
	}
}
.feedback-area {
	@include screen(print) {
		display:none;
	}

	@include screen(custom, min, $min-md) {
		&.line {border-left:0 !important;border-right:0 !important;border-bottom:0 !important;}
		&.line.bg-gray {border-bottom:1px solid $line-gray !important;}
	}
	@include screen(custom, max, $max-sm) {
		&.line {border:0 !important;}
	}

	position:relative;
	margin:0 -15px;
	padding:38px 0 53px;
	// padding-top:50px;
	// padding-bottom:64px;
	//max-width:100%;
	@include screen(custom, max, $max-lg) {
		margin:0 -15px;
	}
	@include screen(custom, max, $max-sm) {
		background:$bg-white !important;
		border:0 !important;
		padding-top:0;
		padding-bottom:0;
		max-width:none;
	}
	// top thick line
	@include screen(custom, max, $max-sm) {
		&:before {
			@include pseudo();
			width:100%;
			height: 20px;
			background: #f2f2f2;
			margin: 0 0 30px;
			box-sizing:content-box;
		}
	}
	.inner {
		max-width:(660px+60px);
		padding:0 30px;
		margin:0 auto;
		@include screen(custom, max, $max-sm) {
			padding:40px 24px 0;
		}
	}
	// max-width
	.area-title,
	.desc {
		max-width:460px;
		margin:0 auto;
		@include screen(custom, max, $max-sm) {
			max-width:100%;
		}
	}
	// title
	.area-title {
		@include font-family($font-semibold);
		font-weight:normal;
		font-size:32px;
		line-height:40px;
		text-align:center;
		display:block;
		color:$color-black;
		margin-bottom:5px;
		@include screen(custom, max, $max-sm) {
			font-size:28px;
			line-height:36px;
			margin-bottom:1px;
		}
	}
	// desc
	.desc {
		@include font-family($font-regular);
		font-size:16px;
		line-height:24px;
		text-align:center;
		color:$color-dimgray;
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:20px;
		}
	}
	// icons 
	.menu-linkbox-belt {
		position:relative;
		margin:28px 0 0;
		display:table;
		width:100%;
		table-layout:fixed;
		@include screen(custom, max, $max-sm) {
			display:block;
			margin:21px 0 27px;
		}
		.menu-linkbox {
			display:table-cell;
			margin:0;
			@extend %clearfix;
			@include screen(custom, max, $max-sm) {
				clear:both;
				display:block;
				width:auto;
				border-top:1px solid $line-lightgray;
				&:first-child {
					border-top:0;
				}
				margin-right:-6px;
				padding-right:6px;
				background:url('/lg5-common/images/common/icons/arrow-right-nightrider.svg') no-repeat right 50%; 
			}
			a {
				display:block;
				width:100%;
				text-decoration:none;
				@extend %clearfix;
				@include screen(custom, max, $max-sm) {
					padding:14px 0;
				}
			}
			.icon {
				display:block;
				text-align:center;
				width:50px;
				height:50px;
				margin:0 auto;
				img {
					width:100%;
				}
				@include screen(custom, max, $max-sm) {
					float:left;
					width:20px;
					height:20px;
					line-height:0;
				}
			}
			.menu-title {
				display:block;
				text-align:center;
				padding-top:15px;
				font-size:20px;
				line-height:24px;
				@include font-family($font-semibold);
				color:$color-nightrider;
				@include screen(custom, max, $max-sm) {
					float:left;
					font-size:16px;
					height:20px;
					line-height:20px;
					padding-top:0;
					padding-left:13px;
				}
			}
		}
	}
}
