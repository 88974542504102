@charset 'UTF-8';

@import './setting/variables';
@import './support/right-floating-bar';
@import './common/error';
@import './epromoter-ui';

.support-wrap {
	position:relative;
	margin:0;
	width:auto;
	clear:both;
	@extend %clearfix;
	font-size:14px;

	&.row {
		width: auto;
	}

	.icon {
		line-height: 0;
	}

	// common option
	.bg-whitesmoke {background: $bg-whitesmoke;}
	.bg-gray {background:#f8f8f8;}
	.bg-white {background:#ffffff;}
	.bg-gray,
	.bg-white {
		&.line {border:1px solid $line-gray;}
	}

	.slick-dots {
		a, button {
			&:focus {
				outline:1px dotted;outline:5px auto -webkit-focus-ring-color;
			}
		}
	}
	
	// Step Box 
	.step-box-default {
		max-width:1284px;
		width:100%;
		background-color:$bg-whitesmoke;
		border:1px solid $line-gray;
		box-sizing:border-box;
		padding:29px 0;  
		text-align:center;
		.step-box-inner {				
			margin:0 auto;
			padding: 0 10px;
			font-size:0;
			@include screen(lg) {
				max-width: 100%;
			} 
			@include screen(md) {
				width:100%;
				max-width: 100%;
				height:100%;
				display:table;
			} 
		}		
		.step {				
			display:inline-block;
			padding-right:60px;
			margin-right:40px;
			font-size:0;
			@include screen(lg) {
				padding-right:40px;
				margin-right:30px;
			}
			@include screen(md) {
				padding-right:30px;
				margin-right:20px;
				vertical-align:middle;
				display:table-cell;
				width:25%; 
			}
			position:relative;
			&:after {
				content:'';
				display:inline-block;
				background:url('/us/images/support/icons/bg-step.svg') no-repeat 0 0;
				width:40px;
				height:40px;
				position:absolute;
				right:-11px;
				top:50%;
				margin-top:-20px;
				background-size:40px 40px;   
				@include screen(lg) {
					background-size:30px 30px;   
					width:30px;
					height:30px;
					margin-top:-15px;
					right:-11px;
				}
				@include screen(md) {
					background-size:26px 26px;   
					width:26px;
					height:26px;
					margin-top:-13px;
					right:10px;
				}
			}				
			&:first-child {
				padding-left:0;
			}
			&:last-child {
				padding-right:0;
				margin-right: 0;
				&:after {
					background:none;
				}
			}
			.icon {
				position: relative;
				display:inline-block;
				vertical-align: top;
				width:44px;
				height:44px;
				background-color: $bg-nobel;
				border-radius:50%;
				margin-right:18px;
				margin-top: 1px;
				@include screen(md) {
					margin-right:10px;
				}
			}
			&-text {
				display:inline-block;
				vertical-align: middle;
				font-size:16px;
				@include font-family($font-semibold);
				text-align:left;
				@include screen(lg) {
					font-size:14px;
				}
				@include screen(md) {
					font-size:14px;
					width:55%;
				}
				strong {
					display:block;
					font-size:14px;
					color: $color-nero; // /* PJTWAUS-1 20200312 modify */  
					@include font-family($font-bold);
					font-weight:400;
				}
			}
			&.active {
				strong {
					color:$color-carmine;
					/* PJTWAUS-1 : 20200311 add */
					&:before {
    					content: url(/lg5-common/images/common/icons/icon-step-on.svg);
    					display: inline-block;
    					padding-right: 4px;
    					vertical-align: middle;
					}
					/*// PJTWAUS-1 : 20200311 add */
				}
				.icon {
					background-color: $bg-carmine;
				}				
			}
		}
	}
	/* PJTWAUS-1 20200206 modify */
	.tabs-type-liner {
		li {
			display:inline-block;
		}
		
	}
	label.mobile {
		display:none;
		@include screen(custom, max, 850) {
			&.mobile {
				display:block;
			}
		}
	}
	/* //PJTWAUS-1 20200206 modify */
	@import './support/feedback-area';
	@import './support/contact-us';
	@import './support/chat-cta';
}
/* PJTWAUS-1 20200131 modify */
html[hcx="3"], html[hcx="4"] {
	.support-wrap {
		.support-search-area, .support-title {
			.img-wrap {
				.img-area { 
					img {
						visibility: hidden;
					}
				}
			}
			&.bg-white {
				.txt-wrap {
					.title, .desc, a {
						color:#000;
					}
				}
			}
		}	
		.promotion-area-wrap, .support-banner-full, .support-banner {
			.visual {
				img {
					visibility:hidden;
				}
			}
			.text-block {
				&.white {
					.name, .info {
						color:#000;
					}
				}
			}
		}
		.support-banner-type-slendy {
			.banner-image {
				img {
					visibility:hidden;
				}
			}
			.text-block {
				color:#000;
			}
		}
	}	
} 
label.mobile {
	display:none;
	@include screen(custom, max, 850) {
		&.mobile {
			display:block;
		}
	}
}
/* //PJTWAUS-1 20200131 modify */