.chat-cta {
    text-align: center;
    max-width: 800px;
    margin: auto;
    padding: 1rem;
    padding: 16px;
    margin-top: 1rem;
    margin-top: 16px;
    border: 1px solid #dee2e6;
    border-radius:  .25rem;
    .cta-title {
        font-weight: 600;
    }
    @include screen(custom, max, $max-sm) {
        .cta-image {
            display: none;
        }
        .cta-title {
            font-size: 0.875rem;
            font-size: 14px;
            display: block;
        }
        .chatbot-cont-btn {
            padding: 2px 14px;
        }
    }
   
    @include screen(custom, min, $min-md) {
        .cta-title {
            font-size: 1.25rem;
            font-size: 20px;
            display:inline;
            padding: 8px;
        }
    }
}