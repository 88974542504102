.contact-us-link-menu {
	position:relative;
	margin: 70px auto !important;
	padding:50px 0 64px;
	&.line {
		padding-top:40px;
	}
	@include screen(print) {
		display:none;
	}
	@include screen(custom, min, $min-md) {
		&.line {
			border-left:0 !important;
			border-right:0 !important;
			border-bottom:0 !important;
		}
		&.line.bg-gray {
			border-bottom:1px solid $line-gray !important;
		}
	}
	@include screen(custom, max, $max-lg) {
		margin:0 -15px;
	}
	@include screen(custom, max, $max-sm) {
		background:$bg-white !important;
		border:0 !important;
		padding:30px 0 0;
		&.line {
			padding-top:30px;
		}
	}
	// top thick line
	.inner {
		max-width:(960px+60px);
		padding:0 30px;
		margin:0 auto;
		@include screen(custom, max, $max-sm) {
			padding:40px 24px;
			background-color: #f2f2f2;
		}
	}
	// max-width
	.area-title,
	.desc {
		// max-width:460px;
		margin:0 auto;

		/* LGEUS-288 : 20200327 modify */
		& > [color] {
			display: block;
			margin: 0 -390px;
			transition: all .3s ease;
		}
		@include screen(custom, max, 1349) {
			& > [color] {
				margin: 0 -340px;
			}
		}
		@include screen(custom, max, $min-xl) {
			& > [color] {
				margin: 0 -250px;
			}
		}
		@include screen(custom, max, $min-lg) {
			& > [color] {
				margin: 0 -200px;
			}
		}
		@include screen(custom, max, 900) {
			& > [color] {
				margin: 0 -150px;
			}
		}
		@include screen(custom, max, $max-sm) {
			max-width:100%;
			& > [color] {
				margin: 0;
			}
		}
		/* // LGEUS-288 : 20200327 modify */
	}
	// title
	.area-title {
		@include font-family($font-semibold);
		font-weight:normal;
		font-size:32px;
		line-height:40px;
		text-align:center;
		display:block;
		color:$color-black;
		margin-bottom:5px;
		@include screen(custom, max, $max-sm) {
			font-size:28px;
			line-height:36px;
			margin-bottom:1px;
		}
	}
	// desc
	.desc {
		@include font-family($font-regular);
		font-size:16px;
		line-height:24px;
		text-align:center;
		color:$color-dimgray;
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:20px;
		}
	}
	// icons 
	.menu-linkbox-belt {
		position:relative;
		margin:28px 0 0;
		display:table;
		width:100%;
		table-layout:fixed;
		@include screen(custom, max, $max-sm) {
			display:block;
			margin:21px 0 26px;
		}
		.menu-linkbox {
			display:table-cell;
			margin:0;
			@extend %clearfix;
			@include screen(custom, max, $max-sm) {
				clear:both;
				display:block;
				width:auto;
				border-top:1px solid $line-lightgray;
				&:first-child {
					border-top:0;
				}
				margin-right:-6px;
				padding-right:6px;
				background:url('/lg5-common/images/common/icons/arrow-right-nightrider.svg') no-repeat right 50%; 
			}
			a {
				display:block;
				width:100%;
				text-decoration:none;
				@extend %clearfix;
				// &:focus .menu-title,
				// &:hover .menu-title {text-decoration:underline;}
				@include screen(custom, max, $max-sm) {
					padding:14px 0;
					// margin:14px 0;
				}
			}
			.icon {
				display:block;
				text-align:center;
				width:50px;
				height:50px;
				margin:0 auto;
				img {
					width:100%;
				}
				@include screen(custom, max, $max-sm) {
					float:left;
					width:20px;
					height:20px;
					line-height:0;
				}
			}
			.menu-title {
				display:block;
				text-align:center;
				padding-top:15px;
				font-size:20px;
				line-height:24px;
				@include font-family($font-semibold);
				color:$color-nightrider;
				@include screen(custom, max, $max-sm) {
					float:left;
					font-size:16px;
					height:20px;
					line-height:20px;
					padding-top:0;
					padding-left:13px;
				}
			}
		}
	}
	// premium care
	.premium-care-section {
		margin-top: 65px;
		font-size: 20px;
		color: #6b6b6b;
		a{
			color: $color-carmine;
		}
	}

}

// ENT-601
.contact-us-repair {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 4.5rem !important;
	padding: 1.5rem 0;
	@include screen(custom, max, $max-sm) {
		margin-top: 1.5rem !important;
	}
}

// with feedback area
.feedback-area + .contact-us-link-menu {
	// padding-top:40px;
	padding-top:50px;
	@include screen(custom, max, $max-sm) {
		padding:0;
		margin-top:-11px;
	}
}

// ENT-601  remove top thick line and fix padding
.remove-before {
	.inner {
		a {
			margin-top: 1rem;
		}
	}
	@include screen(custom, max, $max-sm) {
		background-color: #f8f8f8 !important;
		margin-top: 24px !important;
		padding-bottom: 24px !important;
		&:before {
			display: none;
		}
	}
}
