@font-face {
	font-family: "LG Smart_Global";
	src: url("/lg5-common-gp/webfonts/LGSmart-Bold.woff2") format("woff2"), url("/lg5-common-gp/webfonts/LGSmart-Bold.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "LG Smart_Global";
	src: url("/lg5-common-gp/webfonts/LGSmart-Italic.woff2") format("woff2"), url("/lg5-common-gp/webfonts/LGSmart-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "LG Smart_Global";
	src: url("/lg5-common-gp/webfonts/LGSmartLight-SemiBold.woff2") format("woff2"), url("/lg5-common-gp/webfonts/LGSmartLight-SemiBold.woff") format("woff");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "LG Smart_Global";
	src: url("/lg5-common-gp/webfonts/LGSmart.woff2") format("woff2"), url("/lg5-common-gp/webfonts/LGSmart.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
/* header */
/* bottom */
@-webkit-keyframes "bgAnimation" {
	0% {
		background-color: #fff;
	}
	30% {
		background-color: #f8eaf0;
	}
	70% {
		background-color: #f8eaf0;
	}
	100% {
		background-color: #fff;
	}
}
@keyframes "bgAnimation" {
	0% {
		background-color: #fff;
	}
	30% {
		background-color: #f8eaf0;
	}
	70% {
		background-color: #f8eaf0;
	}
	100% {
		background-color: #fff;
	}
}
@-webkit-keyframes "epromotorChatLoading" {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes "epromotorChatLoading" {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
.epromotor-widget-position {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	position: relative;
	top: 0;
	right: 0;
}
.epromotor-widget {
	width: 160px;
	height: 56px;
	position: relative;
	z-index: 1000;
	transition: width 0.3s ease-out;
}
.epromotor-widget.epromotor-widget--intro {
	width: 56px;
	.epromotor-widget__image {
		margin-left: 10px;
	}
	.epromotor-widget__text {
		margin-left: 11px;
	}
}
.epromotor-widget.epromotor-widget--message-view {
	.epromotor-widget__message {
		width: 300px;
		opacity: 1;
	}
}
.epromotor-widget--counter {
	.epromotor-widget__counter {
		opacity: 1;
		top: -2px;
	}
}
.epromotor-widget__button {
	width: 100%;
	height: 56px;
	border: 0 none;
	border-radius: 30px;
	background-color: #fff;
	overflow: hidden;
	padding: 0;
	cursor: pointer;
	position: relative;
	z-index: 10;
	border: solid 1px #d62b5a;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
}
.epromotor-widget__inner {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	width: 160px;
	height: 56px;
}
.epromotor-widget__text {
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.8px;
	color: #d62b5a;
	display: block;
	margin-left: 9px;
	margin-bottom: 5px;
	transition: margin-left 0.3s ease-out;
}
.epromotor-widget__image {
	display: block;
	transition: margin-left 0.3s ease-out;
	margin-left: 21px;
	margin-top: 2px;
}
.epromotor-widget__message {
	width: 46px;
	opacity: 0;
	height: 56px;
	transition: width 0.3s ease-out, opacity 0.6s linear;
	position: absolute;
	right: 10px;
	top: 0;
	z-index: 2;
	border-radius: 30px;
	box-shadow: 0 2px 14px 0 rgba(121, 116, 118, 0.3);
	background-color: #ffffff;
	.epromotor-btn {
		position: absolute;
		left: -10px;
		top: 16px;
	}
}
.epromotor-widget__message-wrap {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}
.epromotor-widget__message-inner {
	width: 218px;
	height: 56px;
	padding: 14px 69px 13px 23px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	color: #000000;
}
.epromotor-widget__counter {
	position: absolute;
	z-index: 20;
	display: block;
	background-color: #d21852;
	border-radius: 12px;
	border: solid 1px #ffffff;
	left: 39px;
	top: 2px;
	color: #fff;
	font-size: 11px;
	font-weight: 600;
	line-height: 21px;
	text-align: center;
	min-width: 21px;
	height: 21px;
	padding: 0 4px;
	box-sizing: border-box;
	opacity: 0;
	transition: opacity 0.2s linear, top 0.1s ease-out;
}
.epromotor-widget__image-block {
	border-radius: 30px;
	overflow: hidden;
	width: 56px;
	height: 56px;
	background-color: #d32653;
}
.epromotor-header {
	height: 73px;
	transition: height 0.3s ease-out;
}
.epromotor-header--live {
	.epromotor-counselor__state {
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0;
		color: #fff;
		&::before {
			content: "";
			display: inline-block;
			width: 9px;
			height: 9px;
			background-color: #98c53a;
			border-radius: 50%;
			margin-left: -6px;
		}
	}
	.epromotor-header__inner {
		background: #222222;
	}
	.epromotor-counselor__title {
		color: #fff;
	}
	.epromotor-btn--close {
		path {
			stroke: #fff;
			fill: none;
		}
		&:active {
			fill: none;
			path {
				stroke: #ed7d9a;
				fill: none;
			}
		}
		&:hover {
			fill: none;
			path {
				stroke: #ed7d9a;
				fill: none;
			}
		}
	}
	.epromotor-btn--endchat {
		path {
			fill: #fff;
		}
		&:active {
			fill: none;
			path {
				fill: #ed7d9a;
			}
		}
		&:hover {
			fill: none;
			path {
				fill: #ed7d9a;
			}
		}
	}
	+ {
		.epromotor-contents {
			background: linear-gradient(#222, #fff 66%);
		}
	}
}
.epromotor-header--noconsultation {
	height: 99px;
	.epromotor-counselor__state {
		font-size: 12px;
		letter-spacing: -0.3px;
		text-align: center;
		color: #000;
		margin-top: 6px;
	}
}
.epromotor-header__inner {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #fff;
	border-radius: 14px 14px 0 0;
	transition: background-color 0.3s linear;
}
.epromotor-counselor {
	height: 100%;
	padding-left: 15px;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.epromotor-counselor__photo {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	position: relative;
	margin-right: 10px;
	&::before {
		content: "";
		display: block;
		width: 12px;
		height: 12px;
		background-color: #4de300;
		border: 2px solid #fff;
		box-sizing: border-box;
		position: absolute;
		z-index: 100;
		top: 0;
		left: 30px;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 0.3s linear;
	}
	.epromotor-logo-item {
		+ {
			.epromotor-logo-item {
				margin-right: -31px;
			}
		}
	}
}
.epromotor-counselor__title {
	display: block;
	font-size: 21px;
	font-weight: 600;
	color: #000;
	letter-spacing: -0.3px;
	line-height: 27px;
	transition: color 0.3s linear;
}
.epromotor-counselor__state {
	display: block;
	font-size: 13px;
	color: #000;
	letter-spacing: -0.4px;
	line-height: 15px;
	transition: color 0.3s linear;
	padding-top: 4px;
}
.epromotor-header__right {
	position: absolute;
	right: 18px;
	top: 18px;
}
.epromotor-header__left {
	position: absolute;
	left: 18px;
	top: 18px;
}
.epromotor-bottom {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	position: relative;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
}
.epromotor-bottom--active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-bottom__inner {
	background: linear-gradient(transparent, #fff 66%);
	padding: 14px 15px;
}
.epromotor-bottom__field {
	position: relative;
	border-radius: 25px;
	border: solid 1px #eeeded;
	background-color: #fafafa;
	padding: 4px 5px 0 8px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: flex-end;
	align-items: flex-end;
	min-height: 42px;
	box-sizing: border-box;
	.epromotor-btn {
		margin-bottom: 4px;
	}
	.epromotor-bottom-textarea {
		-webkit-flex: 1;
		flex: 1;
		margin-left: 11px;
		margin-right: 15px;
		margin-bottom: 6px;
	}
}
.epromotor-form-btns {
	display: -webkit-flex;
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	padding-right: 55px;
	height: 100%;
	-webkit-align-items: center;
	align-items: center;
	.epromotor-btn {
		margin-left: 6px;
	}
	.epromotor-btn--send {
		position: absolute;
		right: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
.epromotor-bottom-textarea {
	border: none;
	width: 100%;
	height: 24px;
	-webkit-appearance: none;
	box-shadow: none;
	box-sizing: border-box;
	resize: none;
	overflow: hidden;
	background-color: transparent;
	font-size: 15px;
	color: #000;
	line-height: 20px;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	&:focus {
		border: 0 none;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 15px;
	}
	&::-moz-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 15px;
	}
	&:-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 15px;
	}
	&::placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 15px;
	}
}
.epromotor-bottom-alert {
	position: absolute;
	bottom: 65px;
	left: 15px;
	right: 15px;
	background-color: #fff;
	border-radius: 21px;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.08);
	border: solid 1px #eeeded;
	padding: 8px 17px;
	opacity: 0;
	display: none;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: opacity 0.2s linear, -webkit-transform 0.2s ease-in-out;
	transition: opacity 0.2s linear, transform 0.2s ease-in-out;
	transition: opacity 0.2s linear, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	box-sizing: border-box;
	min-height: 42px;
}
.epromotor-bottom-alert.ready {
	display: -webkit-flex;
	display: flex;
}
.epromotor-bottom-alert.active {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-bottom-alert--arrow {
	left: 50%;
	right: auto;
	-webkit-transform: translateY(100%) translateX(-50%);
	transform: translateY(100%) translateX(-50%);
	&::after {
		content: "";
		width: 24px;
		height: 24px;
		background: url(/lg5-common-gp/images/customer/btn-down-2.svg) no-repeat 0 0;
		margin-right: -6px;
		margin-left: 2px;
	}
}
.epromotor-bottom-alert--warning {
	left: 50%;
	right: auto;
	-webkit-transform: translateY(100%) translateX(-50%);
	transform: translateY(100%) translateX(-50%);
	&::before {
		content: "";
		width: 17px;
		height: 17px;
		background: url(/lg5-common-gp/images/customer/ic-alert-02.svg) no-repeat 0 0;
		margin-right: 6px;
	}
}
.epromotor-bottom-alert--arrow.active {
	opacity: 1;
	-webkit-transform: translateY(0) translateX(-50%);
	transform: translateY(0) translateX(-50%);
}
.epromotor-bottom-alert--warning.active {
	opacity: 1;
	-webkit-transform: translateY(0) translateX(-50%);
	transform: translateY(0) translateX(-50%);
}
.epromotor-bottom-alert__message {
	font-size: 14px;
	font-weight: normal;
	color: #000000;
	line-height: 19px;
}
.epromotor-bottom-error {
	position: absolute;
	bottom: 65px;
	left: 15px;
	right: 15px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 12px;
	padding: 10px 26px 13px 19px;
	opacity: 0;
	display: none;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: opacity 0.2s linear, -webkit-transform 0.2s ease-in-out;
	transition: opacity 0.2s linear, transform 0.2s ease-in-out;
	transition: opacity 0.2s linear, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.epromotor-bottom-error.ready {
	display: block;
}
.epromotor-bottom-error.active {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-bottom-error__message {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	line-height: 19px;
}
.epromotor-btn {
	background-color: transparent;
	border: 0 none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
	&:active {
		text-decoration: none;
		path {
			fill: #A50034;
		}
	}
	&:hover {
		text-decoration: none;
		path {
			fill: #A50034;
		}
	}
}
.epromotor-btn[disabled] {
	&:active {
		path {
			fill: #000;
		}
	}
	&:hover {
		path {
			fill: #000;
		}
	}
}
.epromotor-btn--file {
	input[type=file] {
		overflow: hidden;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		position: absolute;
		padding: 0;
		margin: 0;
		opacity: 0;
		width: 1px;
		height: 1px;
		z-index: 2;
	}
	svg {
		position: relative;
		z-index: 4;
		display: block;
		path {
			fill: #858585;
			stroke: none;
		}
	}
}
.epromotor-btn--file-disabled {
	svg {
		opacity: 0.3;
		&:active {
			path {
				fill: #c9c9c9;
				stroke: none;
			}
		}
		&:hover {
			path {
				fill: #c9c9c9;
				stroke: none;
			}
		}
	}
}
.epromotor-btn--send {
	svg {
		display: block;
	}
	path {
		stroke: #A50034;
		fill: #A50034;
	}
	&:active {
		path {
			stroke: #A50034;
			fill: #A50034;
		}
	}
	&:hover {
		path {
			stroke: #A50034;
			fill: #A50034;
		}
	}
}
.epromotor-btn--send[disabled] {
	opacity: 1;
	path {
		stroke: #858585;
		fill: #858585;
	}
	&:active {
		path {
			stroke: #c9c9c9;
			fill: #c9c9c9;
		}
	}
	&:hover {
		path {
			stroke: #c9c9c9;
			fill: #c9c9c9;
		}
	}
}
.epromotor-btn--base {
	display: block;
	width: 100%;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 42px;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	background-color: #a50034;
	border-radius: 21px;
	text-decoration: none;
	transition: background-color 0.3s linear;
	&:hover {
		color: #ffffff;
		background-color: #740125;
	}
}
.epromotor-btn--base[disabled] {
	background-color: #e2e2e2;
	color: #afafaf;
	opacity: 1;
	&:hover {
		color: #afafaf;
		background-color: #e2e2e2;
	}
}
.epromotor-btn--gray {
	display: block;
	width: 100%;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 42px;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
	background-color: #c9c9c9;
	border-radius: 21px;
	text-decoration: none;
	transition: background-color 0.3s linear;
	&:hover {
		color: #000000;
		background-color: #a4a2a2;
	}
}
.epromotor-btn--gray[disabled] {
	background-color: #e2e2e2;
	color: #afafaf;
	opacity: 1;
	&:hover {
		color: #afafaf;
		background-color: #e2e2e2;
	}
}
.epromotor-btn--color-white {
	color: #fff;
	&:hover {
		color: #ffffff;
	}
}
.epromotor-btn--bubble {
	display: block;
	padding: 0 17px;
	height: 42px;
	line-height: 42px;
	box-sizing: border-box;
	border-radius: 25px;
	background-color: #f8eaf0;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #000000;
	transition: background-color 0.3s linear;
	text-decoration: none;
	img {
		vertical-align: middle;
		margin-right: 3px;
	}
	&:hover {
		color: #000000;
		background-color: #e7c5d3;
	}
}
.epromotor-btn--bubble-selected {
	display: block;
	padding: 0 17px;
	height: 42px;
	box-sizing: border-box;
	border-radius: 25px;
	background-color: #585858;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: #ffffff;
}
.epromotor-btn--endchat {
	font-size: 13px;
	font-weight: normal;
	line-height: 17px;
	letter-spacing: -0.3px;
	text-align: right;
	color: #8c8c8c;
	transition: color 0.3s linear;
	&:hover {
		color: #a50034;
		fill: none;
		path {
			fill: #a50034;
		}
	}
	&:active {
		color: #a50034;
		fill: none;
		path {
			fill: #a50034;
		}
	}
	path {
		fill: #000;
		transition: fill 0.3s linear;
	}
}
.epromotor-btn--chat-style {
	padding: 8px 22px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	color: #000000;
	border-radius: 8px;
	border: solid 1px #d2d2d2;
	background-color: #ffffff;
	&:active {
		border-color: #a50034;
		color: #a50034;
	}
	&:hover {
		border-color: #a50034;
		color: #a50034;
	}
}
.epromotor-btn--close {
	path {
		stroke: #000;
		fill: none;
		transition: stroke 0.3s linear;
	}
	&:active {
		fill: none;
		path {
			stroke: #a50034;
			fill: none;
		}
	}
	&:hover {
		fill: none;
		path {
			stroke: #a50034;
			fill: none;
		}
	}
}
.epromotor-btn-group {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-content: flex-start;
	align-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	.epromotor-btn {
		+ {
			.epromotor-btn {
				margin-top: 6px;
			}
		}
	}
}
.epromotor-btn-group--row {
	-webkit-flex-direction: row;
	flex-direction: row;
	.epromotor-btn {
		+ {
			.epromotor-btn {
				margin-left: 5px;
				margin-top: 0;
			}
		}
	}
}
.epromotor-chat {
	.epromotor-chat__message {
		&:first-child {
			margin-top: 20px;
		}
	}
	padding-bottom: 120px;
	opacity: 0;
	display: none;
	-webkit-transform: translateY(200px);
	transform: translateY(200px);
	transition: opacity 0.3s linear, -webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.epromotor-chat__message {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-end;
	align-items: flex-end;
}
.epromotor-chat__message--sent {
	padding: 3px 17px 3px 20px;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
	.epromotor-chat__string {
		background: #585858;
		color: #ffffff;
	}
	+ {
		.epromotor-chat__message--receive {
			padding-top: 12px;
		}
	}
	.epromotor-chat__time {
		text-align: right;
	}
}
.epromotor-chat__message--sent.epromotor-chat__message--single {
	padding: 15px 17px 15px 80px;
}
.epromotor-chat__message--receive {
	padding: 3px 20px 3px 17px;
	.epromotor-logo-item {
		position: absolute;
		left: 7px;
		top: -9px;
		width: 30px;
		height: 30px;
		background-color: #fff;
		border-radius: 50%;
		z-index: 10;
		img {
			width: 100%;
			height: 100%;
		}
	}
	+ {
		.epromotor-chat__message--sent {
			padding-top: 12px;
		}
	}
}
.epromotor-chat__message--single {
	padding: 3px 80px 3px 20px;
	.epromotor-logo-item {
		top: 0;
	}
}
.epromotor-chat__message--bgmotion {
	.epromotor-chat__string {
		-webkit-animation: bgAnimation 2s linear 0.5s 1;
		animation: bgAnimation 2s linear 0.5s 1;
	}
}
.epromotor-chat__message--etc-group {
	padding: 3px 17px 3px 20px;
}
.epromotor-chat__string {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	color: #000000;
	padding: 15px 18px 17px;
	position: relative;
	word-break: break-word;
	overflow: hidden;
	border-radius: 25px;
	border: solid 1px #e6e6e6;
	background-color: #ffffff;
	max-width: 225px;
	.epromotor-chat__string-head {
		padding: 12px 17px 18px;
		border-bottom: solid 1px #e6e6e6;
	}
	.epromotor-chat__string-body {
		width: 100%;
		padding: 17px 17px 20px;
		box-sizing: border-box;
		background-color: #fafafa;
		strong {
			font-weight: 600;
		}
	}
	.epromotor-chat__string-body--white {
		background-color: #fff;
	}
}
.epromotor-chat__string--contents {
	padding: 0;
}
.epromotor-chat__string--fix-width {
	max-width: 229px;
}
.epromotor-chat-product {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	border: 1px solid #e6e6e6;
	border-radius: 25px;
	max-width: 214px;
	overflow: hidden;
	.epromotor-chat-product__image {
		display: block;
		border-bottom: 1px solid #e6e6e6;
		img {
			display: block;
			width: 214px;
		}
	}
	.epromotor-chat-product__info {
		background-color: #fafafa;
		padding: 0 17px 20px;
		width: 100%;
		box-sizing: border-box;
	}
	.epromotor-chat-product__name {
		display: block;
		margin: 9px 0 0;
		font-size: 14px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: -0.5px;
		color: #000000;
	}
	.epromotor-chat-product__model {
		display: block;
		font-size: 12px;
		font-style: normal;
		line-height: 19px;
		letter-spacing: -0.3px;
		color: #8b8b8b;
		margin-top: 1px;
	}
	.grade {
		margin-top: 4px;
	}
	.epromotor-chat-product__price {
		margin: 8px 0 18px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: flex-end;
		align-items: flex-end;
		strong {
			font-size: 16px;
			font-weight: normal;
			line-height: 1.13;
			letter-spacing: -0.33px;
			color: #000000;
			display: block;
			margin-right: 6px;
		}
		del {
			font-size: 11px;
			letter-spacing: -0.3px;
			color: #8b8b8b;
			display: block;
			margin-right: 6px;
		}
		em {
			font-style: normal;
			font-size: 11px;
			font-weight: normal;
			letter-spacing: -0.3px;
			color: #a50034;
			display: block;
		}
	}
}
.grade {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}
.grade__star {
	display: block;
	height: 10px;
	min-width: 50px;
	position: relative;
	margin-right: 8px;
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0E1MDAzNCI+DQogICAgICAgICAgICA8Zz4NCiAgICAgICAgICAgICAgICA8Zz4NCiAgICAgICAgICAgICAgICAgICAgPGc+DQogICAgICAgICAgICAgICAgICAgICAgICA8Zz4NCiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8Zz4NCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTQuOTcxIDEuMDgxTDYuMjQgMy41MTlsMi43NS40MDItMS45OTUgMS45NDIuNDcgMi43NDNMNSA3LjMxIDIuNTM2IDguNjA2bC40Ny0yLjc0M0wxLjAxNCAzLjkybDIuNzU1LS40MDNMNC45NzEgMS4wOHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03NC4wMDAwMDAsIC01MzEuMDAwMDAwKSB0cmFuc2xhdGUoMC4wMDAwMDAsIDgzLjAwMDAwMCkgdHJhbnNsYXRlKDcuMDAwMDAwLCA5OC4wMDAwMDApIHRyYW5zbGF0ZSgxMC4wMDAwMDAsIDE1MC4wMDAwMDApIHRyYW5zbGF0ZSgxNy4wMDAwMDAsIDE5Ny4wMDAwMDApIHRyYW5zbGF0ZSg0MC4wMDAwMDAsIDMuMDAwMDAwKSIvPg0KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4NCiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4NCiAgICAgICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgICAgIDwvZz4NCiAgICAgICAgICAgIDwvZz4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg0K") repeat-x 0 50%;
	&::after {
		content: "";
		display: block;
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		height: 10px;
		background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8ZyBmaWxsPSIjQTUwMDM0IiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0E1MDAzNCIgc3Ryb2tlLXdpZHRoPSIxLjUiPg0KICAgICAgICAgICAgPGc+DQogICAgICAgICAgICAgICAgPGc+DQogICAgICAgICAgICAgICAgICAgIDxnPg0KICAgICAgICAgICAgICAgICAgICAgICAgPGc+DQogICAgICAgICAgICAgICAgICAgICAgICAgICAgPGc+DQogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00Ljk3NyAxLjYzNGwxLjEgMi4xMTQgMi4zNzQuMzQ3LTEuNzI1IDEuNjguNDA3IDIuMzc0TDUgNy4wMjhsLTIuMTMyIDEuMTIuNDA3LTIuMzczLTEuNzI1LTEuNjggMi4zODQtLjM0OSAxLjA0My0yLjExMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNC4wMDAwMDAsIC01MzEuMDAwMDAwKSB0cmFuc2xhdGUoMC4wMDAwMDAsIDgzLjAwMDAwMCkgdHJhbnNsYXRlKDcuMDAwMDAwLCA5OC4wMDAwMDApIHRyYW5zbGF0ZSgxMC4wMDAwMDAsIDE1MC4wMDAwMDApIHRyYW5zbGF0ZSgxNy4wMDAwMDAsIDE5Ny4wMDAwMDApIHRyYW5zbGF0ZSgwLjAwMDAwMCwgMy4wMDAwMDApIi8+DQogICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgICAgICAgICA8L2c+DQogICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgPC9nPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+DQo=") repeat-x 0 50%;
	}
}
.grade__star--1 {
	&::after {
		width: 10px;
	}
}
.grade__star--2 {
	&::after {
		width: 20px;
	}
}
.grade__star--3 {
	&::after {
		width: 30px;
	}
}
.grade__star--4 {
	&::after {
		width: 40px;
	}
}
.grade__star--5 {
	&::after {
		width: 50px;
	}
}
.grade__counter {
	font-size: 12px;
	font-weight: normal;
	letter-spacing: -0.3px;
	color: #3e3e3e;
	display: inline-block;
	margin-right: 4px;
}
.grade__participants {
	font-size: 12px;
	font-weight: normal;
	letter-spacing: -0.3px;
	color: #3e3e3e;
	display: inline-block;
	margin-right: 4px;
}
.epromotor-chat__image {
	max-width: 214px;
	border-radius: 25px;
	overflow: hidden;
	position: relative;
	.epromotor-chat__image-inner {
		display: block;
		img {
			display: block;
		}
	}
	.epromotor-chat__image-link {
		display: block;
	}
	.epromotor-chat__image-string {
		position: absolute;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: #ffffff;
		z-index: 2;
		left: 16px;
		right: 16px;
		bottom: 14px;
	}
}
.epromotor-chat__time {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #afafaf;
	min-width: 50px;
	margin: 0 5px;
}
.epromotor-chat__file {
	max-width: 214px;
}
.epromotor-chat__btn-area {
	padding: 20px 0 0 0;
}
.epromotor-chat__download {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	background: #fff;
	border-radius: 25px;
	border: solid 1px #d2d2d2;
	box-shadow: none;
	cursor: pointer;
	text-align: left;
	padding: 12px 64px 15px 18px;
	word-break: break-all;
	position: relative;
	&:focus {
		outline: none;
	}
	.epromotor-chat__download-circle {
		fill: #a50034;
		stroke: #fff;
		stroke-dasharray: 106.8141502221;
		stroke-dashoffset: 107;
		transition: stroke-dashoffset 0.2s ease-out;
		stroke-linecap: round;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
	.epromotor-chat__download-img {
		g {
			stroke: #fff;
		}
		position: absolute;
		left: 9px;
		top: 8px;
	}
	&:disabled {
		.epromotor-chat__download-circle {
			fill: #f2f2f2;
			stroke: #f2f2f2;
		}
		.epromotor-chat__download-img {
			opacity: 0.3;
			g {
				stroke: #707070;
			}
		}
	}
	.epromotor-chat__download-text {
		color: #000000;
		line-height: 1.29;
		display: block;
	}
	.epromotor-chat__download-volume {
		font-size: 12px;
		line-height: 1.5;
		color: #979797;
		display: block;
		margin-top: 3px;
	}
	.epromotor-chat__download-icon {
		display: block;
		position: absolute;
		right: 15px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
.epromotor-chat__download--active {
	.epromotor-chat__download-circle {
		fill: #f2f2f2;
		stroke: #a50034;
	}
	.epromotor-chat__download-img {
		g {
			stroke: #a50034;
		}
	}
}
.epromotor-chat__description {
	display: block;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #d00042;
}
.epromotor-chat-alert {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 17px;
	letter-spacing: normal;
	text-align: left;
	color: #a50034;
	margin-bottom: 15px;
	padding-left: 21px;
	position: relative;
	img {
		display: block;
		position: absolute;
		left: 0;
		top: 2px;
	}
}
.epromotor-chat-category {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 0 17px;
	.epromotor-btn {
		margin: 3px 2px;
	}
}
.epromotor-form-textarea {
	border: 1px solid #dedede;
	width: 100%;
	height: 82px;
	padding: 8px 13px;
	-webkit-appearance: none;
	box-shadow: none;
	box-sizing: border-box;
	resize: none;
	overflow: visible;
	background-color: #fff;
	border-radius: 20px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 18px;
	letter-spacing: normal;
	color: #000000;
	-ms-overflow-style: none;
	display: block;
	&::-webkit-scrollbar {
		display: none;
	}
	&:focus {
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 14px;
	}
	&::-moz-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 14px;
	}
	&:-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 14px;
	}
	&::placeholder {
		color: rgba(0, 0, 0, 0.35);
		font-size: 14px;
	}
}
.epromotor-input {
	width: 100%;
	box-sizing: border-box;
	padding: 0 13px;
	height: 42px;
	border-radius: 21px;
	border: solid 1px #e6e6e6;
	background-color: #fff;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 18px;
	letter-spacing: normal;
	color: #000000;
	&:focus {
		outline: none;
	}
	+ {
		.epromotor-form__alert {
			margin-left: 13px;
		}
	}
}
.epromotor-form {
	padding: 0;
	.epromotor-form__item {
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.epromotor-form__label {
		display: block;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: #000000;
		margin-bottom: 8px;
		i {
			color: #d00042;
		}
	}
	.epromotor-form__label-checkbox {
		position: relative;
		padding-left: 26px;
		display: block;
		.epromotor-form__checkbox {
			position: absolute;
			width: 1px;
			height: 1px;
			z-index: 1;
			opacity: 0;
			left: 2px;
			top: 2px;
			&:focus {
				outline: none;
			}
			&:checked {
				+ {
					.epromotor-form__label-inner {
						&::before {
							border-color: #fff;
						}
						&::after {
							border-color: #a50034;
							background-color: #a50034;
						}
					}
				}
			}
			&:disabled {
				+ {
					.epromotor-form__label-inner {
						&::before {
							border-color: #fff;
						}
						&::after {
							border-color: #c3c3c3;
							background-color: #c3c3c3;
						}
					}
				}
			}
		}
		.epromotor-form__label-inner {
			display: block;
			i {
				color: #d00042;
			}
			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: solid 1px #e6e6e6;
				background-color: #ffffff;
				transition: border-color 0.1s linear, background-color 0.1s linear;
			}
			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 8px;
				top: 6px;
				z-index: 3;
				width: 3px;
				height: 6px;
				border-right: 1px solid #bebebe;
				border-bottom: 1px solid #bebebe;
				border-color: #bebebe;
				-webkit-transform: rotate(46deg);
				transform: rotate(46deg);
				transition: border-color 0.1s linear;
			}
		}
		.epromotor-form__label-link {
			font-weight: 600;
			text-decoration: underline;
			color: #000;
			&:hover {
				color: #000;
			}
			&:active {
				color: #000;
			}
		}
	}
}
.epromotor-form--disabled {
	position: relative;
	color: #afafaf !important;
	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
	}
	* {
		color: #afafaf !important;
	}
	.epromotor-form__label-checkbox {
		.epromotor-form__checkbox {
			&:checked {
				+ {
					.epromotor-form__label-inner {
						&::after {
							background-color: #e2e2e2 !important;
							border-color: #e2e2e2 !important;
						}
					}
				}
			}
		}
	}
	.epromotor-btn {
		background-color: #e2e2e2 !important;
	}
	.epromotor-star__item {
		path {
			fill: #ddd !important;
		}
		g {
			stroke: #ddd !important;
		}
	}
}
.epromotor-form__alert {
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	color: #a50034;
	margin-top: 5px;
	display: block;
}
.epromotor-form__description {
	font-size: 13px;
	font-weight: 300;
	line-height: 1.23;
	letter-spacing: normal;
	color: #535353;
}
.epromotor-form--support {
	padding: 0 17px;
	.epromotor-form__label {
		font-size: 13px;
	}
	.epromotor-form__item {
		margin-bottom: 15px;
	}
	.epromotor-form-textarea {
		height: 60px;
	}
}
.epromotor-star {
	width: 164px;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	margin-bottom: 20px;
	+ {
		.epromotor-chat__description {
			margin: 0 0 8px;
		}
	}
	.epromotor-star__range {
		cursor: pointer;
		width: 164px;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate3d(-50%, -50%, 0);
		transform: translate3d(-50%, -50%, 0);
		-webkit-appearance: none;
		margin: 0;
		opacity: 0;
		height: 24px;
		&:focus {
			outline: none;
			box-shadow: none;
			background: transparent;
		}
	}
	.epromotor-star__item {
		display: block;
		path {
			fill: #a50034;
			fill-opacity: 0;
			transition: fill-opacity 0.3s linear;
		}
	}
	.epromotor-star__item.active {
		path {
			fill-opacity: 1;
		}
	}
	.epromotor-star__display {
		width: 164px;
		height: 24px;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}
}
.epromotor-selectbox {
	position: relative;
}
.epromotor-selectbox__form {
	width: 100%;
	box-sizing: border-box;
	padding: 0 13px;
	height: 42px;
	border-radius: 21px;
	border: solid 1px #e6e6e6;
	background-color: #fff;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 18px;
	letter-spacing: normal;
	color: #000000;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	&:focus {
		outline: none;
	}
	&::-ms-expand {
		display: none;
	}
}
.epromotor-selectbox__arrow {
	position: absolute;
	right: 17px;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(0deg);
	transform: translateY(-50%) rotate(0deg);
}
.epromotor-loading {
	width: 76px;
	height: 30px;
	border-radius: 25px;
	margin: 20px auto;
	background-color: #f2f2f2;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 60px;
	z-index: 10;
	.epromotor-loading__dot {
		display: block;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-content: center;
		align-content: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		&::after {
			content: "";
			display: block;
			width: 6px;
			height: 6px;
			background-color: #2c2c2c;
			border-radius: 50%;
			margin: 0 3.5px;
			-webkit-animation-name: epromotorChatLoading;
			animation-name: epromotorChatLoading;
			-webkit-animation-duration: 1s;
			animation-duration: 1s;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease-in-out;
			animation-timing-function: ease-in-out;
			-webkit-animation-delay: 0.4s;
			animation-delay: 0.4s;
		}
		&::before {
			content: "";
			display: block;
			width: 6px;
			height: 6px;
			background-color: #2c2c2c;
			border-radius: 50%;
			margin: 0 3.5px;
			-webkit-animation-name: epromotorChatLoading;
			animation-name: epromotorChatLoading;
			-webkit-animation-duration: 1s;
			animation-duration: 1s;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease-in-out;
			animation-timing-function: ease-in-out;
		}
		em {
			content: "";
			display: block;
			width: 6px;
			height: 6px;
			background-color: #2c2c2c;
			border-radius: 50%;
			margin: 0 3.5px;
			-webkit-animation-name: epromotorChatLoading;
			animation-name: epromotorChatLoading;
			-webkit-animation-duration: 1s;
			animation-duration: 1s;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease-in-out;
			animation-timing-function: ease-in-out;
			-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-indent: 110%;
			line-height: 2;
			font-size: 0;
		}
	}
}
.epromotor-support {
	padding-bottom: 50px;
	opacity: 0;
	display: none;
	-webkit-transform: translateY(200px);
	transform: translateY(200px);
	transition: opacity 0.3s linear, -webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	.epromotor-chat__message {
		&:first-child {
			margin-top: 20px;
		}
	}
}
.epromotor-support--ready {
	display: block;
	left: 0;
	right: 0;
	position: absolute;
}
.epromotor-support--active {
	opacity: 1;
	position: relative;
	left: auto;
	right: auto;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-support__icon {
	text-align: center;
	padding: 49px 0 23px;
}
.epromotor-support__header {
	text-align: center;
	color: #000000;
	padding-bottom: 36px;
	h2 {
		font-size: 19px;
		font-weight: 600;
		margin: 0 0 13px;
		padding: 0;
		line-height: 23px;
		letter-spacing: -0.3px;
	}
	p {
		font-size: 14px;
		line-height: 18px;
	}
}
.epromotor-support__btn-area {
	padding: 50px 0 44px;
	.epromotor-btn {
		width: 200px;
		margin: 0 auto;
	}
}
html {
	box-sizing: content-box;
	width: 100%;
	height: 100%;
	p {
		margin: 0;
	}
}
body {
	box-sizing: content-box;
	width: 100%;
	height: 100%;
	p {
		margin: 0;
	}
}
button {
	font-family: "LG Smart_Global", Segoe UI, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
}
textarea {
	font-family: "LG Smart_Global", Segoe UI, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
	&::-webkit-input-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&::-moz-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&:-ms-input-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&::placeholder {
		font-size: 14px;
		color: #afafaf;
	}
}
input {
	font-family: "LG Smart_Global", Segoe UI, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
	&::-webkit-input-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&::-moz-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&:-ms-input-placeholder {
		font-size: 14px;
		color: #afafaf;
	}
	&::placeholder {
		font-size: 14px;
		color: #afafaf;
	}
}
.epromotor-body {
	font-family: "LG Smart_Global", Segoe UI, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
	font-weight: normal;
}
.epromotor-dimd {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0.3;
	z-index: 1;
	display: none;
}
.epromotor-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-flex-direction: column;
	flex-direction: column;
	overflow: hidden;
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	border-radius: 14px 14px 0 0;
}
.epromotor-container--desktop {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.18);
	margin: 30px;
	border-radius: 8px;
	+ {
		.epromotor-dimd {
			display: none;
		}
	}
	.epromotor-header__inner {
		border-radius: 0;
	}
}
.epromotor-contents {
	-webkit-flex: 1 1 0%;
	flex: 1 1 0%;
	position: relative;
	background-color: #fff;
	transition: background-color 0.3s linear;
	.epromotor-contents__inner {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: #fff;
		border-radius: 14px 14px 0 0;
		box-shadow: 0 -4px 23px 0 rgba(0, 0, 0, 0.1);
	}
}
.epromotor-chat--ready {
	display: block;
	position: absolute;
	right: 0;
	left: 0;
}
.epromotor-chat--active {
	position: relative;
	left: auto;
	right: auto;
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-date {
	font-size: 11px;
	color: #afafaf;
	text-align: center;
	padding: 20px 0;
}
.epromotor-date--thick {
	color: #535353;
	font-size: 12px;
	letter-spacing: 0.6px;
}
.epromotor-date__time {
	color: #afafaf;
	font-size: 11px;
	letter-spacing: 0;
}
.epromotor-confirm-layer {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	z-index: 2;
	transition: background-color 0.2s linear;
}
.epromotor-confirm-layer__inner {
	position: absolute;
	left: 15px;
	right: 15px;
	z-index: 2;
	top: 50%;
	padding: 10px 19px 13px;
	border-radius: 12px;
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
	border: solid 1px #eeeded;
	background-color: #ffffff;
	-webkit-transform: translateY(-20%);
	transform: translateY(-20%);
	opacity: 0;
	transition: opacity 0.2s linear, -webkit-transform 0.3s ease;
	transition: opacity 0.2s linear, transform 0.3s ease;
	transition: opacity 0.2s linear, transform 0.3s ease, -webkit-transform 0.3s ease;
}
.epromotor-confirm-layer.ready {
	display: block;
}
.epromotor-confirm-layer.active {
	background-color: rgba(0, 0, 0, 0.5);
	.epromotor-confirm-layer__inner {
		opacity: 1;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
.epromotor-confirm-layer__contents {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000000;
	margin-bottom: 28px;
}
.epromotor-widget-area {
	position: fixed;
	bottom: 20px;
	right: 99px;
	z-index: 99998;
	opacity: 0;
	-webkit-transform: translateY(200px);
	transform: translateY(200px);
	display: none;
	transition: opacity 0.3s linear, -webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.epromotor-widget-area.ready {
	display: block;
}
.epromotor-widget-area.active {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.epromotor-chat-area {
	position: fixed;
	display: none;
	opacity: 0;
	right: 20px;
	bottom: 40px;
	width: 375px;
	height: 100%;
	min-height: 250px;
	max-height: 704px;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: opacity 0.3s linear, -webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out;
	transition: opacity 0.3s linear, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.18);
	border-radius: 14px;
	overflow: hidden;
	z-index: 99999;
}
.epromotor-chat-area.ready {
	display: block;
}
.epromotor-chat-area.active {
	opacity: 1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
}
.epromotor-chatbot-area {
  position: fixed;
  display: none;
  opacity: 0;
  right: 20px;
  bottom: 40px;
  width: 375px;
  height: 100%;
  border-width: 0px;
  min-height: 250px;
  max-height: 704px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: opacity 0.3s linear, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.18);
  border-radius: 14px;
  overflow: hidden;
  z-index: 99999;
}
.epromotor-chatbot-area.ready {
  display: block;
}
.epromotor-chatbot-area.active {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
html[dir=rtl] {
	.epromotor-widget__image {
		transition: margin-right 0.3s ease-out;
		margin-right: 21px;
		margin-left: 0;
	}
	.epromotor-widget__text {
		margin-left: 0;
		margin-right: 9px;
		transition: margin-right 0.3s ease-out;
	}
	.epromotor-widget__message {
		right: auto;
		left: 10px;
		.epromotor-btn {
			right: -10px;
			left: auto;
		}
	}
	.epromotor-widget__message-inner {
		padding: 14px 23px 13px 69px;
		text-align: right;
	}
	.epromotor-widget.epromotor-widget--intro {
		.epromotor-widget__image {
			margin-left: 2px;
			margin-right: 10px;
		}
	}
	.epromotor-bottom-alert--warning {
		&::before {
			margin-right: 0;
			margin-left: 6px;
		}
	}
	.epromotor-bottom-alert--arrow {
		&::after {
			margin-right: 2px;
			margin-left: -6px;
		}
	}
	.epromotor-chat__message--receive {
		.epromotor-logo-item {
			right: 7px;
			left: auto;
		}
	}
	.epromotor-chat__string {
		text-align: right;
	}
	.epromotor-btn--bubble {
		img {
			margin-right: 0;
			margin-left: 3px;
		}
	}
	.grade__star {
		margin-right: 0;
		margin-left: 8px;
	}
	.grade__counter {
		margin-right: 0;
		margin-left: 4px;
	}
	.grade__participants {
		margin-right: 0;
		margin-left: 4px;
	}
	.epromotor-form-textarea {
		text-align: right;
	}
	.epromotor-chat__message--etc-group {
		padding: 3px 20px 3px 17px;
	}
	.epromotor-chat__message--single {
		padding: 3px 20px 3px 80px;
	}
	.epromotor-header--live {
		.epromotor-counselor__state {
			&::before {
				margin-right: -6px;
				margin-left: 0;
			}
		}
	}
	.epromotor-input {
		text-align: right;
	}
	.epromotor-confirm-layer__contents {
		text-align: right;
	}
	.epromotor-btn-group--row {
		.epromotor-btn {
			+ {
				.epromotor-btn {
					margin-right: 5px;
					margin-left: 0;
				}
			}
		}
	}
	.epromotor-bottom__field {
		.epromotor-bottom-textarea {
			margin-right: 11px;
			margin-left: 15px;
		}
	}
	.epromotor-chat__download {
		padding: 12px 18px 15px 64px;
	}
	.epromotor-chat__download-text {
		text-align: right;
	}
	.epromotor-chat__download-volume {
		text-align: right;
	}
	.epromotor-chat__download-icon {
		right: auto;
		left: 15px;
	}
	.epromotor-chat-alert {
		text-align: right;
	}
	.epromotor-chat-product__price {
		strong {
			margin-right: 0;
			margin-left: 6px;
		}
		del {
			margin-right: 0;
			margin-left: 6px;
		}
	}
	.epromotor-chat-product__name {
		text-align: right;
	}
	.epromotor-chat-product__model {
		text-align: right;
	}
	.epromotor-form__label-checkbox {
		padding-right: 26px;
		padding-left: 0;
	}
	.epromotor-form__label-inner {
		&::before {
			right: 8px;
			left: auto;
		}
		&::after {
			right: 0;
			left: auto;
		}
	}
}
@media (min-width: 768px) {
	iframe.epromotor-chatbot-area {
		max-height: 85.3%;
	}
}
@media (max-width: 1439px) {
	.epromotor-widget-area {
		left: 30px;
		right: auto;
	}
}
@media (max-width: 767px) {
	.epromotor-chat-area {
		width: 100%;
		min-height: auto;
		max-height: 100%;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		border-radius: 0;
	}
	.epromotor-container {
		position: absolute;
		left: 0;
		top: 15px;
		right: 0;
		bottom: 0;
	}
	.epromotor-dimd {
		display: block;
	}

	.epromotor-chatbot-area {
		width: 100%;
		min-height: auto;
		max-height: 100%;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		border-radius: 0;
	}
}
