// Right floating bar

// html scroll-y banding on now position
.band-scroll {
	@include screen(custom, max, 1440) {
		position : relative;
		overflow: hidden;
	}
}

.right-floating-bar, .business-floating-bar {
	// area variable
	$floatingbar-bgcolor:$color-white; // $ffffff
	$floatingbar-cube-bgcolor:$color-black; //#000000
	$floatingbar-bordercolor:$color-nightrider; // #333333
	$product-font-color :$color-dimgray; // #4e4e4e
	$pagi-arrow-color:$line-gray; // #cecece
	$floatingbar-rounding : 3px;

	@mixin cubic($size){
		width:$size;
		height:$size;
	}
	// inheritance
	z-index:100;
	@include font-family($font-semibold);
	color:$color-white;

	// default
	ul, ol, li {
		@extend %listreset;
	}
	a {
		display:block; 
		width:100%; height:100%;
		color:inherit;
		text-decoration:none;
	}
	em {font-style:normal;}
	button {
		padding: 0;
		background-color:transparent;
		border:none;
	}
	img {
		width:100%;
		vertical-align:top;
	}
	svg {
		fill : currentColor;
		vertical-align: top;
	}
	.head-title, .icon {
		display:inline-block;
		vertical-align: middle;
	}

	// slider default
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
		.slick-track, .slick-list {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			-o-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		.slick-list {
			position: relative;
			overflow: hidden;
			display: block;
			margin: 0;
			padding: 0;
		
			&:focus {
				outline: none;
			}
		}
		.slick-track {
			position: relative;
			left: 0; top: 0;
			display: block;
			margin:0 auto;
		
			&:before,&:after {
				content: "";
				display: table;
			}
		
			&:after {
				clear: both;
			}
		}
		.slick-slide {
			float: left;
			height: 100%;
			min-height: 1px;
			[dir="rtl"] & {
				float: right;
			}
			&.dragging img {
				pointer-events: none;
			}
		}
		&.slick-initialized {
			display: block;
		}
	}
	.rolling-area {
		display:none;
		max-height:230px;
		overflow:hidden;
	}
	
	.lg-feedback {
		background: #333;
		display: flex;
		flex-direction: column;
		max-width: 50px;
		align-items: start;
		border-radius: 10px 0 0 10px;
		right: 0;
		bottom: 50%;
		position: fixed;
		height: 80px;
		transform: translate(20px);
		&:hover{
			transform: translate(10px);
			transition: transform 300ms 100ms ease-out, opacity 500ms 500ms ease-out;
		}
		.icon, .head-title{
			transform: rotate(-90deg);
		}
		.head-title{
			margin: 30px -10px 0px;
		}
	}
	

	//desktop
	@include screen(custom, min, 1440) {
		position: absolute;
		top:416px; right:0;
		//overflow:hidden;
		text-align:right;
		border-radius:$floatingbar-rounding;
		//inheritance
		font-size:14px;
		line-height: 20px;

		&.fixed {
			position: fixed;
			top: 110px;
		}
		.call-bar-menu-list {
			display:none;
		}
		.bar-menu-list {
			width:180px;
			text-align:left;
			border-radius:$floatingbar-rounding;

			// animation
			transition:margin 300ms ease-out;
		}
		.bar-menu {
			width:100%;
			background-color:$floatingbar-cube-bgcolor
		}
		.bar-menu:first-child{
			border-top-left-radius:$floatingbar-rounding;
			border-top-right-radius:$floatingbar-rounding;
		}
		.bar-menu:last-child{
			border-bottom-left-radius:$floatingbar-rounding;
			border-bottom-right-radius:$floatingbar-rounding;
		}
		.bar-menu.recently-viewed{
			background-color:$floatingbar-bgcolor;
			color:$floatingbar-cube-bgcolor;
		}
		.bar-menu-head {
			position:relative;
			width:100%;
			height:50px;
			border-bottom:1px solid $floatingbar-bordercolor;
			box-sizing:content-box;
			.head-title {
				margin-left: 8px;				
			}
			h2{
				font-family: "LG Smart SemiBold",sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
			.icon {
				height: 50px;
				display: inline-block;
				position: relative;
				padding: 15px 0 15px 15px;
			}
			.icon img, .icon svg {
				@include cubic(24px);
			}
		}
		// exception style
		.bar-menu-list.fold .bar-menu.livechat .icon {padding-left:18px;}
		.bar-menu.livechat .icon {padding-left:13px;}
		.bar-menu.livechat .icon svg {
			width:24px; height:21px;
		}
		// recently-viewed
		
		.bar-menu.recently-viewed {
			border:1px solid $floatingbar-cube-bgcolor;
			background-color:$floatingbar-bgcolor;
			color:$floatingbar-cube-bgcolor;
			.not-yet-view, .rolling-area {
				border-top:1px solid $floatingbar-cube-bgcolor;
				border-bottom:1px solid $floatingbar-cube-bgcolor;
			}
			.not-yet-view {
				display:none;
				text-align:center;
				&.active {
					display:block;
				}
				.guide-paragraph {
					padding:52px 0;
				}
				.guide-text {
					display:inline-block;
					width:100%;
					font-size:12px;
					line-height: 14px;
					margin-top:16px;
				}
				.icon {
					display:block;
					width:64px;
					margin:0 auto 18px;
				}
			}
			.head-inner {cursor:default;}
			.head-inner:hover .head-title {
				text-decoration: none;
			}
			.bar-menu-head {
				border-bottom:none;
			}
			.bar-menu-head .count-viewed {
				position: absolute;
				top:7px; right:-8px;
				@include cubic(18px);
				background-color:$color-carmine;
				@include font-family($font-light);
				font-size:12px;
				color:$color-white;
				line-height:18px;
				text-align:center;
				border-radius:50%;

			}
			.bar-menu-detail {
				padding:0 15px;
			}
			.viewed-bundle {
				.viewed-item {margin-top:12px;}
				.viewed-item:last-child {margin-bottom:12px;}
				.imagbox, .model-number {
					display:inline-block;
					vertical-align:middle;
				}
				.imagbox {
					width:60px;
				}
				.model-number {
					width:calc(100% - (60px + 10px));
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					margin-left:10px;
					font-size:12px;
					line-height: 20px;
					color:$product-font-color;
				}
			}
			// recently viewed controller
			.viewed-bundle-handler {
				padding:5px 0 22px;
			}
			.status-bundle-location {
				display:inline-block;
				vertical-align:top;
				.stand {color:$color-carmine;}
			}
			.quiver {
				float:right;
				margin-top:5px;
				// use slick :: standard
				.slick-arrow {
					position: relative;
					@include cubic(20px);
					background-color:$floatingbar-bgcolor;
					border:1px solid $pagi-arrow-color;
					text-align: center;
					// default text hiding
					white-space: nowrap;
					overflow: hidden;
					color:transparent;
					text-indent:-9999em;
					line-height: 0;
					// icon
					background-position:50% 50%;
					background-size:20px 20px;
					background-repeat:no-repeat;
				}
				.slick-prev {
					background-image:url(/lg5-common/images/common/icons/arrow-left.svg);
					margin-right:4px;
				}
				.slick-next {
					background-image:url(/lg5-common/images/common/icons/arrow-right.svg);
				}

				// to generate button elements :: option custom
				font-size:16px; // icon size 
				[class*="-bundle"]{
					display:inline-block;
					vertical-align: top;
					@include cubic(20px);
					background-color:$floatingbar-bgcolor;
					border:1px solid $pagi-arrow-color;
					text-align: center;
					cursor: pointer;
				}
				.guidetext {
					position: absolute;
					z-index:-100;
					left:-100000px;
					opacity: 0;
				}
			}
		}
		// end expand
		
		// area status :: fold
		.bar-menu-list.fold{
			margin-right : -120px;
			.bar-menu-head .head-title {
				margin-left:25px;
			}
			.bar-menu-head .icon {
				padding: 15px 0 15px 18px;
			}
		}
		.to-egg {
			&.bar-menu.recently-viewed .bar-menu-head {
				background-color:$floatingbar-cube-bgcolor;
				color:$floatingbar-bgcolor;
			}
			.bar-menu-detail {
				display:none;
			}
		}
		// end fold

		// back to top button
		&.fixed {
			.back-to-top {
				opacity:1;
				transform: translateX(0);
				z-index:100;
			}
		}
		.back-to-top {
			position:fixed;
			transition:opacity 0.8s ease-out, transform 0.5s ease-out;
			right:20px;
			bottom:20px;
			opacity:0;
			z-index:-100;
			transform: translateX(110px);
			width:50px;
			height:50px;
			$mkt-float : (
				bg-graywhite : #e5e5e5,
				bg-black : #333333
			);
			background:url(/lg5-common/images/common/icons/arrow-up-nightrider.svg) 50% 50% no-repeat rgba( map-get($mkt-float, bg-graywhite), 0.75) ;
			background-size:20px 20px;
			border-radius:50%;
			margin-top:15px;
			line-height:0;
			button{
				width:100%;
				height:100%;
				padding:0;
				border:none;
				background:none;
				cursor:pointer;
			}
			.icon {display:none;}
			.top-text {width:0;height:0;overflow:hidden;display:block;}
		}
	}

	// mobile
	@include screen(custom, max, 1439) {
		// extend
		%shapemenu {
			@include cubic(40px);
			border-radius:50%;
		}
		position:fixed;
		bottom:15px; right:15px;
		font-size:14px;
		.bar-menu-list{margin-bottom: 10px;}
		.bar-menu.recently-viewed{
			display:none;
		}
		.bar-menu{
			@extend %shapemenu;
			position:relative;
			background-color:$floatingbar-bgcolor;
			&:not(:first-child){
				margin-top: 15px;
			}
			.bar-menu-head {
				height:100%;
				.head-title{
					position: absolute;
					top: 50%; margin-top:-10px;
					right:100%; margin-right:10px;
					white-space: nowrap;
				}
				.icon {
					position: absolute;
					top:50%; left:50%;
					transform:translate(-50%, -50%);
					color:$floatingbar-cube-bgcolor;
				}
			}
			// exception style
			&.livechat .icon {margin-top: -1px;}
		}
		.call-bar-menu-list{
			@extend %shapemenu;
			display:block;
			background-color:$color-carmine;
			.icon {max-width:20px;}
		}
		// back to top button
		.back-to-top {
			display:inline-block;
			margin-top: 3px;
			margin-top: 5px;
			width:40px;
			height:40px;
			overflow:hidden;
			border-radius:50%;
			background-color:$color-dimgray;
			color:$floatingbar-bgcolor;
			text-align: center;
			@extend %shapemenu;
			.icon {max-width:20px;}
			.top-text {
				display:block;
				text-transform: uppercase;
				margin:-6px 0 3px;
				@include font-family($font-bold);
				font-size:11px;
			}
		}
		.back-layer-by-mobile {
			display:none;
			position:fixed; z-index:-1;
			top:0; bottom:0; left:0; right:0;
			background-color:rgba(0,0,0,0.7);
			&.active{
				display:block;
			}
		}

		// animation
		.bar-menu-list {
			position:absolute;
			visibility:hidden;
			opacity:0;
			transition:opacity 0s 0s ease-out;
		}
		.call-bar-menu-list, .call-bar-menu-list .icon {
			transition:transform 300ms 100ms ease-out;
		}
		.back-to-top{
			transition:opacity 300ms;
		}
		.chat.support {
			bottom: 60px; 
			right: 15px;
		}
		.chat.support .chatbot-linker{
			width: 60px; 
			height: 60px
		}
		// active menu list
		&.active {
			.bar-menu-list{
				position:static;
				visibility:visible;
				opacity:1;
				height:auto;
				transition:transform 300ms 100ms ease-out, opacity 500ms 500ms ease-out;
			}

			// animation
			.call-bar-menu-list .icon {
				transform: rotate(-135deg);
			}
			.bar-menu-list, .call-bar-menu-list {
				transform: translateY(45px);
			}
			.back-to-top{
				position:relative; z-index:-1;
				opacity:0;
			}
		}
	}

	@include screen(custom, max, $max-sm){
		.right-floating-bar, .business-floating-bar{
			bottom: 35%;
			.chat.support, .chat.business{
				right: 20px;
			}
		}
	}
	// print
	@include screen(print){
		display:none;
	}
}
